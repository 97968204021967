import loadPolyfills from '../brighteon/load_polyfills';
import { start } from '../brighteon/common';

start();

function loaded() {
  const StandaloneAccountContainer = require('../brighteon/containers/standalone_account_container').default;
  const AccountActionButton = require('../brighteon/features/ui/containers/account_action_button_container').default;
  const React             = require('react');
  const ReactDOM          = require('react-dom');
  const mountNode         = document.getElementById('brighteon-statuses');
  const RelationshipsNode = document.getElementById('relationships-accounts-container');

  if (mountNode !== null) {
    const props = JSON.parse(mountNode.getAttribute('data-props'));
    ReactDOM.render(<StandaloneAccountContainer {...props} />, mountNode);
  }

  if (RelationshipsNode) {
    const FollowButtons = RelationshipsNode.getElementsByClassName('relationship-action-button');

    for (let index = 0; index < FollowButtons.length; index++) {
      const props = JSON.parse(FollowButtons[index].getAttribute('data-props'));
      ReactDOM.render(<AccountActionButton {...props} />, FollowButtons[index]);
    }
  }
}

function main() {
  const ready = require('../brighteon/ready').default;
  ready(loaded);
}

loadPolyfills().then(main).catch(error => {
  console.error(error);
});
