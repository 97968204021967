import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { expandAccountTimeline } from 'brighteon/actions/timelines';
import { List as ImmutableList, Map as ImmutableMap } from 'immutable';
import StatusContainer from './containers/status_container';
import LoadMore from '../../../components/load_more';

const getOrder = () => {
  let order;

  if (window.location.search.includes('?order=reblogs')) {
    order = 'reblogs';
  } else if (window.location.search.includes('?order=replies')) {
    order = 'replies';
  } else if (window.location.search.includes('?order=favourites')) {
    order = 'favourites';
  }

  return order;
};

const generateTimelineId = function (accountId, pinned, unpinned) {
  if (window.location.pathname.slice(0, 2) === '/@') {
    let onlyReplies = false, onlyImage = false, onlyVideo = false, statusId = false, withReplies = false, aggregatedReplies = false, order = null;
    if (window.location.pathname.includes('/with_replies')) {
      aggregatedReplies = true;
      withReplies = true;
    } else if (window.location.pathname.includes('/photos')) {
      onlyImage = true;
      withReplies = true;
    } else if (window.location.pathname.includes('/videos')) {
      onlyVideo = true;
      withReplies = true;
    } else if (window.location.pathname.split('/').length === 3) {
      statusId = true;
      withReplies = true;
    } else if (window.location.pathname.split('/').length === 2) {
      withReplies = false;
    } else {
      withReplies = false;
    }

    order = getOrder();

    return `account:${accountId}${withReplies ? ':with_replies' : ''}${onlyReplies ? ':only_replies' : ''}${aggregatedReplies ? ':aggregated_replies' : ''}${onlyImage ? ':only_image' : ''}${onlyVideo ? ':only_video' : ''}${statusId ? ':specific' : ''}${pinned ? ':pinned' : ''}${unpinned ? ':unpinned' : ''}${order ? ':order' : ''}`;
  }
  return '';
};

const getParams = function (pinned, unpinned) {
  const params = {};
  if (window.location.pathname.slice(0, 2) === '/@') {
    if (window.location.pathname.includes('/with_replies')) {
      params.aggregatedReplies = true;
      params.withReplies = true;
    } else if (window.location.pathname.includes('/photos')) {
      params.onlyImage = true;
      params.withReplies = true;
    } else if (window.location.pathname.includes('/videos')) {
      params.onlyVideo = true;
      params.withReplies = true;
    } else if (window.location.pathname.split('/').length === 3) {
      params.statusId = window.location.pathname.split('/')[2];
      params.withReplies = true;
    } else if (window.location.pathname.split('/').length === 2) {
      params.withReplies = false;
    } else {
      params.withReplies = false;
    }

    params.order = getOrder();
  }
  return {
    ...params,
    pinned,
    unpinned,
  };
};

const mapStateToProps = (state, { accountId, pinned, unpinned }) => {
  const timelineId = generateTimelineId(accountId, pinned, unpinned);
  const timeline = state.getIn(['timelines', timelineId], ImmutableMap());

  // Filter only account's boosts and replies
  let statusIds = timeline.get('items', ImmutableList());

  return {
    statusIds: statusIds,
    statuses: state.get('statuses', ImmutableMap()),
    isLoading: timeline.get('isLoading', false),
    hasMore: timeline.get('hasMore', false),
  };
};

export default @connect(mapStateToProps)
class AccountTimeline extends React.PureComponent {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    statusIds: ImmutablePropTypes.list.isRequired,
    statuses: ImmutablePropTypes.map,
    isLoading: PropTypes.bool.isRequired,
    hasMore: PropTypes.bool.isRequired,
    local: PropTypes.bool,
    username: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    statusId: PropTypes.any,
    accountId: PropTypes.any,
    pinned: PropTypes.any,
    unpinned: PropTypes.any,
  };

  componentDidMount() {
    this._connect();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.local !== this.props.local) {
      this._connect();
    }
  }

  _connect() {
    const { dispatch, accountId, pinned, unpinned } = this.props;

    let params = getParams(pinned, unpinned);

    dispatch(expandAccountTimeline(accountId, params));
  }

  handleLoadMore = () => {
    const { dispatch, accountId, statusIds, pinned, unpinned, statuses } = this.props;
    const maxId = statusIds.last();
    let params = getParams(pinned, unpinned);

    if (maxId) {
      const requestParams = {
        maxId,
        ...params,
      };

      if (params.order) {
        requestParams.count = statuses.getIn([maxId, `${params.order}_count`]);
      }

      dispatch(expandAccountTimeline(accountId, requestParams));
    }
  };

  render() {
    const { statusIds, username, avatar, statusId: statusIdProp, isLoading, hasMore, pinned, unpinned } = this.props;
    const loadMore = hasMore ? <LoadMore visible={!isLoading} onClick={this.handleLoadMore} /> : null;

    return (
      <Fragment>
        {
          statusIds.map(statusId => (
            <StatusContainer
              key={`f-${statusId}`}
              id={statusId}
              onMoveUp={null}
              onMoveDown={null}
              contextType={'public'}
              username={username}
              avatar={avatar}
              statusId={statusIdProp}
              showThread
              showPinnedMsg={pinned || unpinned ? true : false}
              pinned
            />
          ),
          )
        }
        {loadMore}
      </Fragment>
    );
  }

}
