import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import configureStore from '../../../store/configureStore';
import { hydrateStore } from '../../../actions/store';
import { IntlProvider, addLocaleData } from 'react-intl';
import { getLocale } from '../../../locales';
import initialState from '../../../initial_state';
import {
  followAccount,
  unfollowAccount,
} from 'brighteon/actions/accounts';

const { localeData, messages } = getLocale();
addLocaleData(localeData);

const store = configureStore();

if (initialState) {
  store.dispatch(hydrateStore(initialState));
}

export default class AccountActionButtonContainer extends React.PureComponent {

  static propTypes = {
    locale: PropTypes.string,
    status: PropTypes.object,
    target_account_id: PropTypes.string,
  };

  static defaultProps = {
    local: !initialState.settings.known_fediverse,
  };

  state = {
    status: 'following',
    target_account_id: '0',
  }

  componentDidMount() {
    this.setState({
      status: this.props.status,
      target_account_id: this.props.target_account_id,
    });
  }

  handleFollow = () => {
    store.dispatch(followAccount(this.state.target_account_id));
    this.setState({ status: 'following' });
  }

  handleUnfollow = () => {
    store.dispatch(unfollowAccount(this.state.target_account_id));
    this.setState({ status: 'unfollow' });
  }

  render() {
    const { locale } = this.props;
    const status = this.state.status;

    return (
      <IntlProvider locale={locale} messages={messages}>
        <Provider store={store}>
          <Fragment>
            <div className='account-action'>
              {
                status === 'unfollow' && (
                  <div className='button normal-button' onClick={this.handleFollow} role='button' tabIndex={0} >Follow</div>
                )
              }
              {
                status === 'following' && (
                  <div className='button normal-button button--destructive' onClick={this.handleUnfollow} role='button' tabIndex={0} >
                    <span className='normal'>Following</span>
                    <span className='hover'>Unfollow</span>
                  </div>
                )
              }
            </div>
          </Fragment>
        </Provider>
      </IntlProvider>
    );
  }

}
