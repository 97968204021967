import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import Avatar from 'brighteon/components/avatar';
import RelativeTimestamp from 'brighteon/components/relative_timestamp';
import DisplayName from 'brighteon/components/display_name';
import StatusContent from 'brighteon/components/status_content';
import { StatusActionBar } from './components';
import AttachmentList from 'brighteon/components/attachment_list';
import Card from 'brighteon/features/status/components/card';
import { injectIntl, FormattedMessage } from 'react-intl';
import ImmutablePureComponent from 'react-immutable-pure-component';
import { MediaGallery, Video, Audio } from 'brighteon/features/ui/util/async-components';
import classNames from 'classnames';
import Icon from 'brighteon/components/icon';
import { displayMedia } from 'brighteon/initial_state';
import api from 'brighteon/api';
import { checkIfAndroid } from 'brighteon/is_mobile';
import Immutable from 'immutable';

// We use the component (and not the container) since we do not want
// to use the progress bar to show download progress
import Bundle from 'brighteon/features/ui/components/bundle';
import { importFetchedStatuses } from 'brighteon/actions/importer';

import { connect } from 'react-redux';
import { StatusReplyContainer } from './containers';
import ComposeFormContainer from '../../../../../../brighteon/components/status_with_reply/containers/compose_form_container';

export const textForScreenReader = (intl, status, rebloggedByText = false) => {
  const displayName = status.getIn(['account', 'display_name']);

  const values = [
    displayName.length === 0 ? status.getIn(['account', 'acct']).split('@')[0] : displayName,
    status.get('spoiler_text') && status.get('hidden') ? status.get('spoiler_text') : status.get('search_index').slice(status.get('spoiler_text').length),
    intl.formatDate(status.get('created_at'), { hour: '2-digit', minute: '2-digit', month: 'short', day: 'numeric' }),
    status.getIn(['account', 'acct']),
  ];

  if (rebloggedByText) {
    values.push(rebloggedByText);
  }

  return values.join(', ');
};

export const defaultMediaVisibility = (status) => {
  if (!status) {
    return undefined;
  }

  if (status.get('reblog', null) !== null && typeof status.get('reblog') === 'object') {
    status = status.get('reblog');
  }

  return (displayMedia !== 'hide_all' && !status.get('sensitive') || displayMedia === 'show_all');
};

const mapDispatchToProps = dispatch => ({
  importFetchedStatuses: (...args) => dispatch(importFetchedStatuses(...args)),
});

const mapStateToProps = state => ({
  me: state.getIn(['meta', 'me']),
});

export default @connect(mapStateToProps, mapDispatchToProps) @injectIntl
class Status extends ImmutablePureComponent {

  static contextTypes = {
    router: PropTypes.object,
  };

  static propTypes = {
    statuses: ImmutablePropTypes.map,
    status: ImmutablePropTypes.map,
    account: ImmutablePropTypes.map,
    accounts: ImmutablePropTypes.map,
    otherAccounts: ImmutablePropTypes.list,
    onClick: PropTypes.func,
    onReply: PropTypes.func,
    onFavourite: PropTypes.func,
    onReblog: PropTypes.func,
    onDelete: PropTypes.func,
    onDirect: PropTypes.func,
    onMention: PropTypes.func,
    onPin: PropTypes.func,
    onOpenMedia: PropTypes.func,
    onOpenVideo: PropTypes.func,
    onBlock: PropTypes.func,
    onEmbed: PropTypes.func,
    onHeightChange: PropTypes.func,
    onToggleHidden: PropTypes.func,
    onToggleCollapsed: PropTypes.func,
    muted: PropTypes.bool,
    hidden: PropTypes.bool,
    unread: PropTypes.bool,
    onMoveUp: PropTypes.func,
    onMoveDown: PropTypes.func,
    showThread: PropTypes.bool,
    showPinnedMsg: PropTypes.bool,
    getScrollPosition: PropTypes.func,
    updateScrollBottom: PropTypes.func,
    cacheMediaWidth: PropTypes.func,
    cachedMediaWidth: PropTypes.number,
    scrollKey: PropTypes.string,
    username: PropTypes.string,
    avatar: PropTypes.string,
    statusId: PropTypes.any,
    replyOrigin: PropTypes.any,
    onOpenRepliesModal: PropTypes.func,
    onOpenReblogsModal: PropTypes.func,
    onOpenFavouritesModal: PropTypes.func,
  };

  // Avoid checking props that are functions (and whose equality will always
  // evaluate to false. See react-immutable-pure-component for usage.
  updateOnProps = [
    'status',
    'account',
    'muted',
    'hidden',
  ];

  state = {
    showMedia: new Immutable.Map({}),
    statusId: undefined,
    rerenderFlag: false,
    showReplyBox: true,
    showAllReplies: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.status && nextProps.status.get('id') !== prevState.statusId) {
      return {
        // showMedia: defaultMediaVisibility(nextProps.status),
        statusId: nextProps.status.get('id'),
      };
    } else {
      return null;
    }
  }

  handleToggleMediaVisibility = (status) => () => {
    const statusId = status.get('id');
    const currentShowMediaOpt = this.state.showMedia.get(statusId);
    const newShowMedia = this.state.showMedia.set(statusId, currentShowMediaOpt ? false : true);
    this.setState({ showMedia: newShowMedia });
  }

  handleClick = (status) => () => {
    window.open(status.get('url'), '_blank');
  }

  handleExpandClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick();
      return;
    }

    if (e.button === 0) {
      if (!this.context.router) {
        return;
      }

      const { status } = this.props;
      this.context.router.history.push(`/statuses/${status.getIn(['reblog', 'id'], status.get('id'))}`);
    }
  }

  handleAccountClick = () => {
    // if (this.context.router && e.button === 0 && !(e.ctrlKey || e.metaKey)) {
    //   const id = e.currentTarget.getAttribute('data-id');
    //   e.preventDefault();
    //   this.context.router.history.push(`/accounts/${id}`);
    // }
  }

  handleExpandedToggle = () => {
    this.props.onToggleHidden(this._properStatus());
  }

  handleCollapsedToggle = isCollapsed => {
    this.props.onToggleCollapsed(this._properStatus(), isCollapsed);
  }

  renderLoadingMediaGallery() {
    return <div className='media-gallery' style={{ height: '110px' }} />;
  }

  renderLoadingVideoPlayer() {
    return <div className='video-player' style={{ height: '110px' }} />;
  }

  renderLoadingAudioPlayer() {
    return <div className='audio-player' style={{ height: '110px' }} />;
  }

  handleOpenVideo = (media, options) => {
    this.props.onOpenVideo(media, options);
  }

  handleHotkeyOpenMedia = e => {
    const { onOpenMedia, onOpenVideo } = this.props;
    const status = this._properStatus();

    e.preventDefault();

    if (status.get('media_attachments').size > 0) {
      if (status.getIn(['media_attachments', 0, 'type']) === 'audio') {
        // TODO: toggle play/paused?
      } else if (status.getIn(['media_attachments', 0, 'type']) === 'video') {
        onOpenVideo(status.getIn(['media_attachments', 0]), { startTime: 0 });
      } else {
        onOpenMedia(status.get('media_attachments'), 0);
      }
    }
  }

  handleHotkeyReply = e => {
    e.preventDefault();
    this.props.onReply(this._properStatus(), this.context.router.history);
  }

  handleHotkeyFavourite = () => {
    this.props.onFavourite(this._properStatus());
  }

  handleHotkeyBoost = e => {
    this.props.onReblog(this._properStatus(), e);
  }

  handleHotkeyMention = e => {
    e.preventDefault();
    this.props.onMention(this._properStatus().get('account'), this.context.router.history);
  }

  handleHotkeyOpen = () => {
    this.context.router.history.push(`/statuses/${this._properStatus().get('id')}`);
  }

  handleHotkeyOpenProfile = () => {
    this.context.router.history.push(`/accounts/${this._properStatus().getIn(['account', 'id'])}`);
  }

  handleHotkeyMoveUp = e => {
    this.props.onMoveUp(this.props.status.get('id'), e.target.getAttribute('data-featured'));
  }

  handleHotkeyMoveDown = e => {
    this.props.onMoveDown(this.props.status.get('id'), e.target.getAttribute('data-featured'));
  }

  handleHotkeyToggleHidden = () => {
    this.props.onToggleHidden(this._properStatus());
  }

  handleHotkeyToggleSensitive = () => {
    this.handleToggleMediaVisibility(this.props.status)();
  }

  _properStatus() {
    const { status } = this.props;

    if (status.get('reblog', null) !== null && typeof status.get('reblog') === 'object') {
      return status.get('reblog');
    } else {
      return status;
    }
  }

  handleRef = c => {
    this.node = c;
  }

  handleReply = () => {
    // this.props.onReply(this.props.status);
    this.replyTextareaRef.textarea.focus();
  }

  setTextareaRef = (c) => {
    this.replyTextareaRef = c;
  }

  toggleShowAllReplies = () => {
    this.setState({
      showAllReplies: !this.state.showAllReplies,
    });
  }

  // OnInput() {
  //   this.style.height = 'auto';
  //   this.style.height = (this.scrollHeight) + 'px';
  // }

  getDescendants = () => {
    const { status } = this.props;
    let status_id = status.get('reblog') && status.getIn(['reblog', 'id']);
    status_id = status_id || status.get('id');
    api().get(`/api/v1/statuses/${status_id}/context`)
      .then(({ data }) => {
        if (data.descendants.length > 0) {
          this.props.importFetchedStatuses(data.descendants);
        }
        if (data.ancestors.length > 0) {
          this.props.importFetchedStatuses(data.ancestors);
        }
        if (data.ancestors.length > 0 || data.descendants.length > 0) {
          // workaround start: force rerender
          this.setState({
            rerenderFlag: true,
          });
          this.setState({
            rerenderFlag: false,
          });
          // workaround end
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  componentDidMount() {
    if (this.props.status) {
      this.getDescendants();
      if (this.props.status.get('replies_count') === 0) {
        const status = this.props.status.get('reblog');
        if (status && status.get('replies_count') > 0) {
          this.getDescendants();
        }
      }
    }
  }

  ensureShowReplyBox = () => {
    if (checkIfAndroid()) {
      let element = this.replyBox;

      let yPosition = 0;
      while (element) {
        yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
      }
      if (yPosition - window.scrollY > 300) {
        window.scrollBy(0, 200);
      }
    }
  }

  originContent = (status_id) => {
    let originStatus = '';
    let { showThread, avatar, statusId, replyOrigin, ...other } = this.props;
    const status = this.props.statuses.get(status_id);
    if (!status) {
      return '';
    }
    if (status.get('in_reply_to')) {
      originStatus = this.originContent(status.get('in_reply_to_id'));
    }

    const account_id = status.get('account');
    const account = this.props.accounts.get(account_id);
    const replies = Object.values(this.props.statuses.toJS())
      .filter(st => st.in_reply_to_id === status.get('id'))
      .sort((st1, st2) => st1.created_at > st2.created_at ? 1 : -1);
    const repliesCount = replies.length;

    return (
      <>
        {originStatus}
        <div className={classNames('status', `status-${status.get('visibility')}`, { 'status-reply': !!status.get('in_reply_to_id') })} data-id={status.get('id')}>
          <div className='status__expand' onClick={this.handleExpandClick} role='presentation' />
          <div className='status__info'>
            <div>
              <a onClick={this.handleAccountClick} data-id={status.getIn(['account', 'id'])} href={status.getIn(['account', 'url'])} title={status.getIn(['account', 'acct'])} className='status__display-name' target='_blank' rel='noopener noreferrer'>
                <div className='status__avatar'>
                  <Avatar account={account} size={48} />
                </div>
                <DisplayName account={account} />
              </a>
            </div>
            <div>
              <a href={status.get('url')} className='status__relative-time' target='_blank' rel='noopener noreferrer'>
                <RelativeTimestamp timestamp={status.get('created_at')} />
              </a>
            </div>
          </div>

          <StatusContent status={status} onClick={this.handleClick(status)} expanded={!status.get('hidden')} showThread={showThread} onExpandedToggle={this.handleExpandedToggle} collapsable onCollapsedToggle={this.handleCollapsedToggle} />

          {this.mediaSection(status)}

          <StatusActionBar onReply={this.handleReply} repliesCount={repliesCount} {...other} status={status} account={account} />
        </div>
      </>
    );

  }

  mediaSection = (status) => {
    let media = null;
    if (status.get('media_attachments').size > 0) {
      if (this.props.muted) {
        media = (
          <AttachmentList
            compact
            media={status.get('media_attachments')}
          />
        );
      } else if (status.getIn(['media_attachments', 0, 'type']) === 'audio') {
        const attachment = status.getIn(['media_attachments', 0]);

        media = (
          <Bundle fetchComponent={Audio} loading={this.renderLoadingAudioPlayer}>
            {Component => (
              <Component
                src={attachment.get('url')}
                alt={attachment.get('description')}
                poster={attachment.get('preview_url') || status.getIn(['account', 'avatar_static'])}
                backgroundColor={attachment.getIn(['meta', 'colors', 'background'])}
                foregroundColor={attachment.getIn(['meta', 'colors', 'foreground'])}
                accentColor={attachment.getIn(['meta', 'colors', 'accent'])}
                duration={attachment.getIn(['meta', 'original', 'duration'], 0)}
                width={this.props.cachedMediaWidth}
                height={110}
                cacheWidth={this.props.cacheMediaWidth}
              />
            )}
          </Bundle>
        );
      } else if (status.getIn(['media_attachments', 0, 'type']) === 'video') {
        const attachment = status.getIn(['media_attachments', 0]);

        media = (
          <Bundle fetchComponent={Video} loading={this.renderLoadingVideoPlayer}>
            {Component => (
              <Component
                preview={attachment.get('preview_url')}
                blurhash={attachment.get('blurhash')}
                src={attachment.get('url')}
                alt={attachment.get('description')}
                width={this.props.cachedMediaWidth}
                height={110}
                inline
                sensitive={status.get('sensitive')}
                onOpenVideo={this.handleOpenVideo}
                cacheWidth={this.props.cacheMediaWidth}
                visible={this.state.showMedia.get(status.get('id'))}
                onToggleVisibility={this.handleToggleMediaVisibility(status)}
              />
            )}
          </Bundle>
        );
      } else {
        media = (
          <Bundle fetchComponent={MediaGallery} loading={this.renderLoadingMediaGallery}>
            {Component => (
              <Component
                media={status.get('media_attachments')}
                sensitive={status.get('sensitive')}
                height={110}
                onOpenMedia={this.props.onOpenMedia}
                cacheWidth={this.props.cacheMediaWidth}
                defaultWidth={this.props.cachedMediaWidth}
                visible={this.state.showMedia.get(status.get('id'))}
                onToggleVisibility={this.handleToggleMediaVisibility(status)}
              />
            )}
          </Bundle>
        );
      }
    } else if (status.get('spoiler_text').length === 0 && status.get('card')) {
      media = (
        <Card
          onOpenMedia={this.props.onOpenMedia}
          card={status.get('card')}
          cacheWidth={this.props.cacheMediaWidth}
          defaultWidth={this.props.cachedMediaWidth}
          sensitive={status.get('sensitive')}
        />
      );
    }

    return media;
  }

  render () {
    let media = null;
    let statusAvatar, prepend = '', rebloggedByText;

    const { intl, hidden, featured, otherAccounts, unread, showThread, scrollKey, showPinnedMsg, pinned } = this.props;

    let { status, account, username, avatar, statusId, replyOrigin, ...other } = this.props;

    if (status === null) {
      return null;
    }

    if (replyOrigin && status.get('visibility') !== 'public') {
      return '';
    }

    if (hidden) {
      return (
        <div ref={this.handleRef} className={classNames('status__wrapper', { focusable: !this.props.muted })} tabIndex='0'>
          {status.getIn(['account', 'display_name']) || status.getIn(['account', 'username'])}
          {status.get('content')}
        </div>
      );
    }

    if (status.get('filtered') || status.getIn(['reblog', 'filtered'])) {
      return (
        <div className='status__wrapper status__wrapper--filtered focusable' tabIndex='0' ref={this.handleRef}>
          <FormattedMessage id='status.filtered' defaultMessage='Filtered' />
        </div>
      );
    }

    if (replyOrigin) {
      const display_name_html = { __html: status.getIn(['account', 'display_name_html']) };

      prepend = (
        <div className='status__prepend'>
          <FormattedMessage id='status.replied_to' defaultMessage="{origin} replied to {name}'s post" values={{ origin: replyOrigin, name: <a onClick={this.handleAccountClick} data-id={status.getIn(['account', 'id'])} href={status.getIn(['account', 'url'])} className='status__display-name muted'><bdi><strong dangerouslySetInnerHTML={display_name_html} /></bdi></a> }} />
        </div>
      );
    }

    if (pinned && status.get('pinned')) {
      prepend = showPinnedMsg && (
        <>
          {prepend}
          <div className='status__prepend'>
            <div className='status__prepend-icon-wrapper'><Icon id='thumb-tack' className='status__prepend-icon' size={20} /></div>
            <FormattedMessage id='status.pinned' defaultMessage='Pinned post' />
          </div>
          <div className='border' />
        </>
      );
    } else if (status.get('reblog', null) !== null && typeof status.get('reblog') === 'object') {
      const display_name_html = { __html: status.getIn(['account', 'display_name_html']) };

      prepend = (
        <>
          {prepend}
          <div className='status__prepend'>
            <div className='status__prepend-icon-wrapper'><Icon id='retweet' className='status__prepend-icon' size={20} /></div>
            <FormattedMessage id='status.reblogged_by' defaultMessage='{name} reposted' values={{ name: <a onClick={this.handleAccountClick} data-id={status.getIn(['account', 'id'])} href={status.getIn(['account', 'url'])} className='status__display-name muted'><bdi><strong dangerouslySetInnerHTML={display_name_html} /></bdi></a> }} />
          </div>
          <div className='border' />
        </>
      );

      rebloggedByText = intl.formatMessage({ id: 'status.reblogged_by', defaultMessage: '{name} reposted' }, { name: status.getIn(['account', 'acct']) });

      account = status.get('account');
      status = status.get('reblog');
    }

    if (status.get('media_attachments').size > 0) {
      if (this.props.muted) {
        media = (
          <AttachmentList
            compact
            media={status.get('media_attachments')}
          />
        );
      } else if (status.getIn(['media_attachments', 0, 'type']) === 'audio') {
        const attachment = status.getIn(['media_attachments', 0]);

        media = (
          <Bundle fetchComponent={Audio} loading={this.renderLoadingAudioPlayer}>
            {Component => (
              <Component
                src={attachment.get('url')}
                alt={attachment.get('description')}
                poster={attachment.get('preview_url') || status.getIn(['account', 'avatar_static'])}
                backgroundColor={attachment.getIn(['meta', 'colors', 'background'])}
                foregroundColor={attachment.getIn(['meta', 'colors', 'foreground'])}
                accentColor={attachment.getIn(['meta', 'colors', 'accent'])}
                duration={attachment.getIn(['meta', 'original', 'duration'], 0)}
                width={this.props.cachedMediaWidth}
                height={110}
                cacheWidth={this.props.cacheMediaWidth}
              />
            )}
          </Bundle>
        );
      } else if (status.getIn(['media_attachments', 0, 'type']) === 'video') {
        const attachment = status.getIn(['media_attachments', 0]);

        media = (
          <Bundle fetchComponent={Video} loading={this.renderLoadingVideoPlayer}>
            {Component => (
              <Component
                preview={attachment.get('preview_url')}
                blurhash={attachment.get('blurhash')}
                src={attachment.get('url')}
                alt={attachment.get('description')}
                width={this.props.cachedMediaWidth}
                height={110}
                inline
                sensitive={status.get('sensitive')}
                onOpenVideo={this.handleOpenVideo}
                cacheWidth={this.props.cacheMediaWidth}
                visible={this.state.showMedia.get(status.get('id'))}
                onToggleVisibility={this.handleToggleMediaVisibility(status)}
              />
            )}
          </Bundle>
        );
      } else {
        media = (
          <Bundle fetchComponent={MediaGallery} loading={this.renderLoadingMediaGallery}>
            {Component => (
              <Component
                media={status.get('media_attachments')}
                sensitive={status.get('sensitive')}
                height={110}
                onOpenMedia={this.props.onOpenMedia}
                cacheWidth={this.props.cacheMediaWidth}
                defaultWidth={this.props.cachedMediaWidth}
                visible={this.state.showMedia.get(status.get('id'))}
                onToggleVisibility={this.handleToggleMediaVisibility(status)}
              />
            )}
          </Bundle>
        );
      }
    } else if (status.get('spoiler_text').length === 0 && status.get('card')) {
      media = (
        <Card
          onOpenMedia={this.props.onOpenMedia}
          card={status.get('card')}
          cacheWidth={this.props.cacheMediaWidth}
          defaultWidth={this.props.cachedMediaWidth}
          sensitive={status.get('sensitive')}
        />
      );
    }

    statusAvatar = <Avatar account={status.get('account')} size={48} />;

    const avatarStyle = {
      width: '36px',
      height: '36px',
      backgroundSize: '36px 36px',
      backgroundImage: `url(${avatar})`,
    };

    if (!statusId || (statusId === status.get('id'))) {
      const replies = Object.values(this.props.statuses.toJS())
        .filter(st => st.in_reply_to_id === status.get('id'))
        .sort((st1, st2) => st1.created_at > st2.created_at ? 1 : -1);
      const repliesCount = replies.length;

      return (
        <div className={classNames('status__wrapper', `status__wrapper-${status.get('visibility')}`, { 'status__wrapper-reply': !!status.get('in_reply_to_id'), read: unread === false, focusable: !this.props.muted })} tabIndex={this.props.muted ? null : 0} data-featured={featured ? 'true' : null} aria-label={textForScreenReader(intl, status, rebloggedByText)} ref={this.handleRef}>
          {prepend}
          {status.get('in_reply_to') && this.originContent(status.getIn(['in_reply_to', 'id']))}

          <div className={classNames('status', `status-${status.get('visibility')}`, { 'status-reply': !!status.get('in_reply_to_id'), muted: this.props.muted, read: unread === false })} data-id={status.get('id')}>
            <div className='status__expand' onClick={this.handleExpandClick} role='presentation' />
            <div className='status__info'>
              <div>
                <a onClick={this.handleAccountClick} data-id={status.getIn(['account', 'id'])} href={status.getIn(['account', 'url'])} title={status.getIn(['account', 'acct'])} className='status__display-name' target='_blank' rel='noopener noreferrer'>
                  <div className='status__avatar'>
                    {statusAvatar}
                  </div>

                  <DisplayName account={status.get('account')} others={otherAccounts} />
                </a>
              </div>
              <div>
                <a href={status.get('url')} className='status__relative-time' target='_blank' rel='noopener noreferrer'>
                  <RelativeTimestamp timestamp={status.get('created_at')} />
                </a>
              </div>
            </div>

            <StatusContent status={status} onClick={this.handleClick(status)} expanded={!status.get('hidden')} showThread={showThread} onExpandedToggle={this.handleExpandedToggle} collapsable onCollapsedToggle={this.handleCollapsedToggle} />

            {media}

            <StatusActionBar scrollKey={scrollKey} status={status} account={status.get('account')} {...other} onReply={this.handleReply} repliesCount={repliesCount} showAllReplies={this.state.showAllReplies} toggleShowAllReplies={this.toggleShowAllReplies} />

            {
              replies.filter(
                (reply, idx) => (idx < 3 || this.state.showAllReplies),
              ).map((reply) => (
                <StatusReplyContainer
                  key={reply.id}
                  id={reply.id}
                  contextType={this.props.contextType}
                  openComposeModal={this.props.openComposeModal}
                />
              ))
            }

            {
              !this.state.showAllReplies && repliesCount > 3 && (
                <button className='status__content__read-more-button' onClick={this.toggleShowAllReplies}>
                  <span>Show All Replies</span>
                </button>
              )
            }

            {
              this.state.showAllReplies && repliesCount > 3 && (
                <button className='status__content__read-more-button' onClick={this.toggleShowAllReplies}>
                  <span>Show Top 3 Replies</span>
                </button>
              )
            }

            {
              this.state.showReplyBox && this.props.me && (
                <div className='status__reply'>
                  <div className='status__avatar'>
                    <div className='account__avatar' style={avatarStyle} />
                  </div>

                  <div className='status__reply-box'>
                    <ComposeFormContainer passRefCb={this.setTextareaRef} ancestor={this.props.status} getReplies={this.getDescendants} />
                  </div>
                </div>
              )
            }
          </div>
        </div>
      );
    }

    return null;
  }

}
