import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import configureStore from '../store/configureStore';
import { hydrateStore } from '../actions/store';
import { setupBrowserNotifications, expandNotifications } from '../actions/notifications';
import { IntlProvider, addLocaleData } from 'react-intl';
import { getLocale } from '../locales';
import AccountTimeline from '../features/standalone/account_timeline';
import ModalContainer from '../features/ui/containers/modal_container';
import NewPostModalHandler from '../features/ui/containers/new_post_modal_handler';
import LinkFooter from '../features/ui/components/link_footer';
import DonateBoxContainer from '../features/compose/containers/donate_box_container';
import initialState from '../initial_state';
import TopContainer from '../features/compose/containers/top_container';
import { connectChatStream, connectUserStream } from '../actions/streaming';
import { BrowserRouter } from 'react-router-dom';

const { localeData, messages } = getLocale();
addLocaleData(localeData);

const store = configureStore();

if (initialState) {
  store.dispatch(hydrateStore(initialState));
}

export default class StandaloneAccountContainer extends React.PureComponent {

  static propTypes = {
    accountId: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    currentAccountId: PropTypes.string,
    hashtag: PropTypes.string,
    local: PropTypes.bool,
    username: PropTypes.string,
    avatar: PropTypes.string,
    statusId: PropTypes.any,
    pinned: PropTypes.any,
    unpinned: PropTypes.any,
  };

  static defaultProps = {
    local: !initialState.settings.known_fediverse,
  };

  componentDidMount() {
    this.disconnect = store.dispatch(connectUserStream());
    this.disconnectChat = store.dispatch(connectChatStream());
    store.dispatch(expandNotifications());

    store.dispatch(setupBrowserNotifications());
    if (process.env.NODE_ENV === 'production') {
      // avoid offline in dev mode because it's harder to debug, commeting out because this does not work for profile page and independent status page
      // require('offline-plugin/runtime').install();
      // store.dispatch(registerPushNotifications.register());
    }
  }

  componentWillUnmount () {
    if (this.disconnect) {
      this.disconnect();
      this.disconnectChat();
      this.disconnect = null;
      this.disconnectChat = null;
    }
  }

  render () {
    const { locale, username, accountId, avatar, statusId } = this.props;

    let show_pinned = true;
    if (window.location.pathname.slice(0, 2) === '/@') {
      if (window.location.pathname.includes('/videos') ||
        window.location.pathname.includes('/photos') ||
        window.location.pathname.includes('/with_replies')) {
        show_pinned = false;
      }
    }

    const donate_box_container = document.getElementById('donate-modal-container');
    if (donate_box_container) {
      ReactDOM.render(
        <DonateBoxContainer />,
        donate_box_container,
      );
    }

    const navigation_container = document.getElementById('brighteon-navigation');
    const new_post_section     = document.getElementById('new-post-section');
    const link_footer_node     = document.getElementById('link-footer');

    return (
      <IntlProvider locale={locale} messages={messages}>
        <Provider store={store}>
          <BrowserRouter>
            <Fragment>
              <div className='profile-timeline-container'>
                {
                  show_pinned ? (
                    <>
                      <AccountTimeline username={username} avatar={avatar} statusId={statusId} accountId={accountId} pinned />
                      <AccountTimeline username={username} avatar={avatar} statusId={statusId} accountId={accountId} unpinned />
                    </>
                  ) : (
                    <>
                      <AccountTimeline username={username} avatar={avatar} statusId={statusId} accountId={accountId} />
                    </>
                  )
                }
              </div>

              {
                ReactDOM.createPortal(
                  <ModalContainer />,
                  document.getElementById('modal-container'),
                )
              }
              {
                navigation_container && ReactDOM.createPortal(
                  <TopContainer />,
                  navigation_container,
                )
              }
              {
                new_post_section && ReactDOM.createPortal(
                  <NewPostModalHandler />,
                  new_post_section,
                )
              }
              {
                link_footer_node && ReactDOM.createPortal(
                  <LinkFooter />,
                  link_footer_node,
                )
              }
            </Fragment>
          </BrowserRouter>
        </Provider>
      </IntlProvider>
    );
  }

}
