import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from '../../../../brighteon/components/icon';
import { showComposePopup } from '../../../actions/compose';

const mapStateToProps = state => ({
  showPopup: state.getIn(['compose', 'showPopup']),
});

const mapDispatchToProps = dispatch => ({
  onShowPopup (showPopup) {
    dispatch(showComposePopup(showPopup));
  },
});

class NewPostModalHandler extends React.PureComponent {

  static propTypes = {
    showPopup: PropTypes.bool.isRequired,
    onShowPopup: PropTypes.func,
  };

  handleNewPostModal = () => {
    this.props.onShowPopup(true);
  }

  closeNewPostModal = () => {
    this.props.onShowPopup(false);
  }

  render () {
    return (
      <div>
        <div key='floating-new-post-button' className='floating-new-post-button z2' onClick={this.handleNewPostModal} role='button' tabIndex={0} >
          <Icon id='new-post-white' size={20} />
        </div>
      </div>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(NewPostModalHandler);
